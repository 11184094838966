<script setup>
// import my_hello from './components/my_hello.vue';
import { ref, provide, onMounted } from "vue";
import DrawerSettings from "./components/DrawerSettings.vue";
import DrawerBasket from "./components/DrawerBasket.vue";
import SiteHeader from "./components/SiteHeader.vue";
import CardProduct from "./components/CardProduct.vue";
import FooterMain from "./components/FooterMain.vue";
import PatternsBuro from "./components/PatternsBuro.vue";
import LoaderSite from "./components/LoaderSite.vue";
import ModalRegisters from "./components/ModalRegisters.vue";
import ModalRegister2 from "./components/ModalRegister2.vue";
// import SiteComps from './components/SiteComps.vue';

const drawerState = ref({}); // используем объект вместо булевского значения
const isLoading = ref(true);
// let scrollPosition = 0;

// const isModuleVisible = ref(false);

onMounted(() => {
  // Симуляция задержки для загрузки данных
  setTimeout(() => {
    isLoading.value = false;
  }, 1500); // Задержка в 2 секунды.
});

const closeDrawer = (drawerId) => {
  // if (!drawerState.value[drawerId]?.isOpen) return; // Проверка на то открыт ли drawer
  const drawer = drawerState.value[drawerId];
  if (!drawer || !drawer.isOpen) return;
  // Возвращаем стили для body
  document.body.style.overflow = "";
  document.body.style.position = "";
  document.body.style.top = "";
  document.body.style.width = "";
  // window.scrollTo(0, drawerState.value[drawerId].scrollPosition);
  window.scrollTo(0, drawer.scrollPosition);
  // drawerState.value = false;
  delete drawerState.value[drawerId]; // Удаление конрентого drawer
};

// Функция для открытия конретного drawer
const openDrawer = (drawerId) => {
  const scrollPosition = window.scrollY;
  document.body.style.overflow = "hidden";
  document.body.style.position = "fixed";
  document.body.style.top = `-${scrollPosition}px`;
  document.body.style.width = "100%";
  // document.body.classList.add("model-opened");
  // drawerState.value = true;
  // Устанавливаем состояние для конретного drawer
  drawerState.value[drawerId] = { isOpen: true, scrollPosition };
};

// Функция для открытия modal
const openModel = (moduleId) => {
  const scrollPosition = window.scrollY;
  document.body.style.overflow = "hidden";
  document.body.style.position = "fixed";
  document.body.style.top = `-${scrollPosition}px`;
  document.body.style.width = "100%";
  // Закрыть все Drawer
  Object.keys(drawerState.value).forEach((key) => {
    drawerState.value[key].isOpen = false;
  });
  // Object.keys(drawerState.value).forEach((key) => {
  //   drawerState.value[key].isOpen = false;
  // });
  drawerState.value[moduleId] = { isOpen: true, scrollPosition };
};

provide("drawerState", drawerState);

provide("cartActions", {
  closeDrawer,
  openDrawer,
  openModel,
});
</script>

<template>
  <!-- prettier-ignore   -->
  <LoaderSite v-if="isLoading"/>
  <div v-else class="transistion-opacity duration-500 ease-in-out opacity-100">
    <div>
      <SiteHeader></SiteHeader>
      <DrawerSettings />
      <DrawerBasket />
      <div class="flex justify-center items-center w-full">
        <!-- prettier-ignore   -->
        <button @click="toggleSearch" class="md:hidden mt-16 w-5/6">
          <input
            v-model=" search "
          placeholder="Поиск"
          type="search"
          id="search"
          required
          class="mt-8 block w-full h-12 px-4 border border-gray-300 rounded-2xl 
          shadow-sm focus:outline-none focus:ring-gray-600 focus:border-gray-600 sm:text-sm"
          />
        </button>
        <div v-if="drawerState['module']?.isOpen">
          <ModalRegisters />
        </div>
      </div>
      <!-- prettier-ignore   -->
      <div class="relative flex justify-center items-center w-full h-128 xl:w-full xl:h-full 
      lg:w-full lg:h-full md:w-full md:h-128 sm:w-full sm:h-132 overflow-hidden">
        <img
        alt="Main_img"
        class="w-full h-full object-cover mt-12" 
        src="../public/pizza_background.png"
        />
        <!-- prettier-ignore   -->
        <div class="absolute mt-6 inset-0 flex flex-col items-center justify-center p-4 ">
          <h2 class="text-white font-bold xl:text-5xl xl:mt-10 lg:text-3xl lg:mt-24 
          md:tex t-xl md:mt-32sm:mt-32 smL transform text-xl
          text-center tracking-wider leading-tight" data-aos="fade-up" data-aos-duration="2000">
            Если и учиться, 
            <br />
            то точно не на голодный желудок
          </h2>
          <button class="mt-16 xl:mt-44 lg:mt-32 md:mt-24 sm:mt-16 tracking-wider 
          leading-tight text-white bg-white bg-opacity-10 w-56 h-16 px-4 
          xl:w-96 xl:h-24 lg:w-60 lg:h-20 md:w-52 md:h-14 sm:w-48 sm:h-10
          border-2 border-yellow-500 rounded-3xl text-sm xl:text-2xl lg:text-xl md:text-lg 
          sm:text-sm font-medium hover:bg-yellow-500 active:bg-yellow-400 focus:outline-none 
          focus:ring-2 focus:ring-offset-2 font-bold" data-aos="fade-up" data-aos-duration="2000">
            О нас
          </button>
        </div>
        <div class="absolute bottom-2 w-full text-center text-white p-4 text-xs 
        text-sm xl:text-2xl lg:text-xl md:text-lg sm:text-sm 
        tracking-wider leading-tight" data-aos="fade-up" data-aos-duration="2000">
          <h3>лучшая пиццирия Pizza Ruc, пиццы, десерты</h3>
        </div>
      </div>
    </div>
    <!-- <div class="bg-white h-screen indent-4">  -->
    <CardProduct />
    <!-- prettier-ignore -->
    <div class="mt-14 relative flex justify-center items-center w-full h-128 xl:w-full xl:h-full 
      lg:w-full lg:h-full md:w-full md:h-128 sm:w-full sm:h-132 overflow-hidden">
      <img 
      alt="Constr_buro"
      class="w-full h-full object-cover"
      src="../public/pizza_background2.png"
      />
      <!-- prettier-ignore   -->
      <div class="absolute inset-0 flex flex-col items-center justify-start xl:mt-48 mt-28 xl:w-1/2
      w-full h-full">
        <div class="inline-block text-left">
          <p class="text-white font-bold xl:text-6xl text-2xl
          tracking-wider leading-tight" data-aos="fade-up" data-aos-duration="2000">
            Сделай Свою Пиццу
            <br>
            Сам
          </p>
          <div>
            <p class="xl:mt-8 mt-6 text-white xl:text-2xl text-md
            tracking-wider leading-tight" data-aos="fade-up" data-aos-duration="2000">
              Пицца, созданная по вашим правилам!
              <br>
              Выбирайте основу, соусы, начинки и создавайте
              <br>
              идеальное блюдо.
              <br>
              Мы приготовим и доставим вашу уникальную
              <br>
              пиццу прямо к вашему столу!
            </p>
            <button class="xl:mt-32 mt-10 tracking-wider leading-tight text-white  
            bg-white bg-opacity-25 xl:w-96 xl:h-24 w-44 h-16 px-4 
            xl:rounded-3xl rounded-xl xl:text-2xl text-sm font-medium hover:bg-white 
            hover:text-black active:bg-gray-200 font-bold" data-aos="fade-up" 
            data-aos-duration="2000">
              Собрать пиццу 
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- prettier-ignore -->
    <div class="relative justify-center items-center w-full h-screen">
      <PatternsBuro/>
    </div>
    <div class="relative bottom-0 left-0 w-full bg-black text-white py-4">
      <FooterMain />
    </div>
    <div v-if="drawerState['module']?.isOpen">
      <ModalRegisters />
    </div>
    <div v-if="drawerState['module2']?.isOpen">
      <ModalRegister2 />
    </div>
  </div>
  <!-- <div
    v-if="drawerState['module']?.isOpen"
    class="bg-white fixed w-full h-screen inset-0 z-30 p-10"
  ></div> -->
</template>

<style>
body {
  margin: 0;
  font-family: "Times New Roman", Times, serif;
}
</style>
