<script setup>
import { inject } from "vue";

const { openDrawer } = inject("cartActions");

// const handleOpenDrawer = () => {
//   openDrawer();
// };
</script>

<template>
  <div class="relative">
    <header class="fixed top-0 z-20 left-0 w-full bg-white py-2 border-b">
      <!-- <div class="text-2xl font-bold text-center align-middle">Anwalen</div> -->
      <nav class="container mx-auto md:w-full px-4 py-2 flex items-center justify-between">
        <!-- Настройки -->
        <div class="flex items-center space-x-4">
          <a href="#" @click="openDrawer('drawer2')" class="text-gray-700 hover:text-gray-900">
            <i class="fas fa-settings">
              <img src="../../public/settings.svg" />
            </i>
          </a>
          <!-- Search Input (Hidden on mobile) -->
          <div :class="['flex-1', isSearchVisible ? 'block' : 'hidden md:block']">
            <a href="#" class="flex items-center text-gray-700 hover:text-gray-900">
              <i class="fas fa-search align-middle">
                <img src="../../public/search.svg" alt="Search Icon" class="w-5 h-5 mr-2" />
              </i>
              Поиск
            </a>
          </div>
        </div>
        <!-- Название фирмы -->
        <!-- prettier-ignore   -->
        <div class="absolute left-1/2 transform -translate-x-1/2 text-2xl 
        font-bold text-black">Pizza Ruc</div>

        <div class="flex items-end xl:items-center space-x-4">
          <a href="#" class="text-gray-700 hover:text-gray-900">
            <i class="fas fa-profile">
              <img src="../../public/basket_user.svg" />
            </i>
          </a>
          <a href="#" @click="openDrawer('drawer1')" class="text-gray-700 hover:text-gray-900">
            <i class="fas fa bascet">
              <img src="../../public/profile_user.svg" alt="Profi" />
            </i>
          </a>
        </div>
      </nav>
    </header>
  </div>
</template>

<!-- <script>
export default{
    name: 'Header',
};
</script> -->

<style scoped>
/* Дополнительные стили */
</style>
