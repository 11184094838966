<script setup>
// import { inject } from "vue";
// const drawerState = inject("drawerState");
import { inject } from "vue";
const { openModel } = inject("cartActions");

const handleSubmit = () => {
  openModel("module2");
};
</script>

<template>
  <!-- prettier-ignore -->
  <div>
    <p class="mb-8 font-bold text-4xl">
      Регистрация
    </p>
  </div>
  <!-- Незабыть переделать сайт!!! -->
  <!-- <div class="flex justify-end items-center">
    <div class="bg-white p-4 w-2/12 h-full">
      <p class="font-bold">
        Testing
      </p>
    </div>
  </div> -->
  <!-- prettier-ignore -->
  <div class="justify-start items-start mt-2">
    <div class="bg-white p-4 w-3/5 h-96 rounded-xl">
      <!-- prettier-ignore -->
      <div class="flex flex-col justify-center items-center mt-16">
        <div class="relative xl:w-5/6 w-5/6">
          <div class="flex w-full justify-start items-start">
            <label for="fio" class="block text-sm font-medium text-gray mb-2">
              ФИО
            </label>
          </div>
          <input
          v-model="fio"
          type="text"
          id="text"
          required
          class="mt-1 block w-full px-4 py-4 border border-gray-400 rounded-xl shadow-sm
          focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        </div>
      </div>
      <!-- prettier-ignore -->
      <div class="flex flex-col justify-center items-center mt-8">
        <div class="relative xl:w-5/6 w-5/6">
          <div class="flex w-full justify-start items-start">
            <label for="email" class="block text-sm font-medium text-gray mb-2">
              Почта
            </label>
          </div>
          <input
          v-model="email"
          type="email"
          id="email"
          required
          class="mt-1 block w-full px-4 py-4 border border-gray-400 rounded-xl shadow-sm 
          focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- prettier-ignore -->
  <div class="flex flex-col justify-center items-center ml-56 mt-10">
      <button
      @click="handleSubmit"
      type="button"
      id="button"
      class="transistion bg-litelBlack rounded-xl px-4 py-3 cursor-pointer text-white 
      hover:bg-neutral-800 active:bg-neutral-900 text-center xl:w-36 w-36"
      >
      Дальше
      </button>
  </div>
</template>
