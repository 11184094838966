<script setup>
import { ref } from "vue";
// const drawerState = inject("drawerState");
const showPassword = ref(false);
const showPassword2 = ref(false);

const togglePass = () => {
  showPassword.value = !showPassword.value;
  showPassword2.value - !showPassword2.value;
};
</script>

<template>
  <!-- prettier-ignore -->
  <div>
    <p class="mb-8 font-bold text-4xl mt-20 mb-8">
      Создание аккаунта
    </p>
  </div>
  <!-- prettier-ignore -->
  <div class="justify-start items-start mt-2">
    <div class="bg-white p-4 w-3/5 h-128 rounded-xl">
      <!-- prettier-ignore -->
      <div class="flex flex-col justify-center items-center mt-16">
        <div class="relative xl:w-5/6 w-5/6">
          <div class="flex w-full justify-start items-start">
            <label for="username" class="block text-sm font-medium text-gray mb-2">
              Логин
            </label>
          </div>
          <input
          v-model="fio"
          type="text"
          id="text"
          required
          class="mt-1 block w-full px-4 py-4 border border-gray-400 rounded-xl shadow-sm
          focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        </div>
      </div>
      <!-- prettier-ignore -->
      <div class="flex flex-col justify-center items-center mt-8">
        <div class="relative xl:w-5/6 w-5/6">
          <div class="flex w-full justify-start items-start">
            <label for="password" class="block text-sm font-medium text-gray mb-2">
              Пароль
            </label>
          </div>
          <input
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          id="password"
          required
          class="mt-1 block w-full px-4 py-4 border border-gray-400 rounded-xl shadow-sm 
          focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        <span 
          @click="togglePass"
          class="absolute inset-y-0 mt-8 right-2 pr-3 flex items-center  
          cursor-pointer text-gray-500">
          {{ showPassword ? '👁️' : '🙈' }}  
        </span>
        </div>
      </div>
      <!-- prettier-ignore -->
      <div class="flex flex-col justify-center items-center mt-8">
        <div class="relative xl:w-5/6 w-5/6">
          <div class="flex w-full justify-start items-start">
            <label for="confirm_password" class="block text-sm font-medium text-gray mb-2">
              Подверждение пароля
            </label>
          </div>
          <input
          v-model="confirm_password"
          :type="showPassword2 ? 'text' : 'confirm_password'"
          id="confirm_password"
          required
          class="mt-1 block w-full px-4 py-4 border border-gray-400 rounded-xl shadow-sm 
          focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        <span 
          @click="togglePass"
          class="absolute inset-y-0 mt-8 right-2 pr-3 flex items-center  
          cursor-pointer text-gray-500">
          {{ showPassword2 ? '👁️' : '🙈' }}  
        </span>
        </div>
      </div>
    </div>
  </div>
  <!-- prettier-ignore -->
  <div class="flex flex-col justify-center items-center ml-56 mt-10">
      <button
      @click="handleSubmit"
      type="button"
      id="button"
      class="transistion bg-litelBlack rounded-xl py-4 cursor-pointer text-white 
      hover:bg-neutral-800 active:bg-neutral-900 text-center xl:w-36 w-36"
      >
      Создать
      </button>
  </div>
</template>
