<template>
  <div class="flex justify-center mt-8 xl:mt-20" data-aos="fade-up" data-aos-duration="2000">
    <h2 class="xl:text-4xl text-3xl text-center font-bold">Пицца</h2>
  </div>
  <!-- prettier-ignore -->
  <div class="bg-white h-full indent-4 flex flex-col items-center">
    <div class="flex flex-wrap justify-center mt-12 grid gap-8 xl:grid-cols-4 grid-cols-2">
      <!-- Не убирать prettier игнор так как будет вызываться ошибка... -->
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <img
        alt="pizza1"
        class="w-full h-full object-cover"
        src="../../public/pizza_menu_1.png"
        />
      </div>
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <img
        alt="pizza1"
        class="w-full h-full object-cover"
        src="../../public/pizza_menu_2.png"
        />
      </div>
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <img
        alt="pizza1"
        class="w-full h-full object-cover"
        src="../../public/pizza_menu_3.png"
        />
      </div>
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <img
        alt="pizza1"
        class="w-full h-full object-cover"
        src="../../public/pizza_menu_4.png"
        />
      </div>
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <img
        alt="pizza1"
        class="w-full h-full object-cover"
        src="../../public/pizza_menu_5.png"
        />
      </div>
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <img
        alt="pizza1"
        class="w-full h-full object-cover"
        src="../../public/pizza_menu_6.png"
        />
      </div>
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <img
        alt="pizza1"
        class="w-full h-full object-cover"
        src="../../public/pizza_menu_7.png"
        />
      </div>
      <!-- prettier-ignore -->
      <div
        class="bg-gray-200 flex justify-center items-center rounded-lg 
        xl:h-72 xl:w-72 h-36 w-36 cursor-pointer transition hover:-translate-y-2 hover:shadow-xl"
      >
        <img
        alt="pizza1"
        class="w-full h-full object-cover"
        src="../../public/pizza_menu_8.png"
        />
      </div>
    </div>
    <!-- prettier-ignore -->
    <button
      type="button"
      id="button"
      class="mt-20 xl:w-1/6 w-3/12 px-4 p-4 border
      border-black rounded-3xl text-xl
      font-medium text-black bg-transparent hover:bg-gray-100 active:bg-gray-200 
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 
      hover:border-indigo-500 cursor-pointer">
      Посмотреть полный каталог
    </button>
  </div>
</template>
