<template>
  <!-- prettier-ignore   -->
  <div class="relative inset-0 flex flex-col justify-center items-center w-full h-full 
  overflow-hidden">
  <img
    alt="Main_img"
    class="w-full h-full object-cover" 
    src="../../public/pizza_background3.png"
  />
  </div>
</template>
