<script setup>
// import { data } from "autoprefixer";
import { inject } from "vue";
import DrawerHead from "./DrawerBasketHead.vue";
import DrawerLog from "./DrawerLog.vue";
// import ModalRegisters from "./ModalRegisters.vue";
// Получаем состояние и функции из App.vue
const drawerState = inject("drawerState");
// const { openDrawer } = inject("cartActions");
const { closeDrawer } = inject("cartActions");

const handleClose = (drawerId) => {
  closeDrawer(drawerId);
};
// let scrollPosition = 0;

// function openDrawers(){
//   scrollPosition = window.scrollY;
//   drawerState.value = true;
// }
</script>

<template>
  <!-- <div class="fixed top-0 left-0 h-full w-full bg-black z-10 opacity-70"></div> -->
  <!-- Overlay and Drawer component -->
  <!-- prettier-ignore -->
  <div :class="['flex-1', isDrawerVisible ? 'block' : 'hidden md:block']">
    <transition name="fade">
      <div v-if = "drawerState['drawer1']?.isOpen" 
      class="fixed inset-0 z-30 flex opacity-80 cursor-pointer">
        <!-- Over lay -->
        <!-- prettier-ignore -->
        <div class="fixed inset-0 bg-black opacity-80 cursor-pointer" 
        @click="handleClose('drawer1')">
        </div>
      </div>
    </transition>
  </div>
  <!-- prettier-ignore -->
  <div :class="['flex-1', isDrawerVisible ? 'block' : 'hidden md:block']">
    <transition name="drawer" mode="out-in">
      <div v-if="drawerState['drawer1']?.isOpen" class="fixed inset-0 z-30 flex justify-end">
        <!-- prettier-ignore -->
        <div class="bg-white w-1/2 h-screen fixed inset-0 right-0 top-0 z-30 p-10 shadow-lg p-4 
          overflow-y-auto transition-transform transform translate-x-full">
          <DrawerHead />
          <DrawerLog />
        </div>
      </div>
    </transition>
  </div>
  <!-- prettier-ignore -->
  <div v-if="drawerState['drawer1']?.isOpen" class="md:hidden bg-white w-full h-screen fixed 
  inset-0 z-30 p-10">
    <DrawerHead />
    <DrawerLog />
  </div>
  <!-- testing fast closing driver with input creating users -->
  <!-- <div v-if="drawerState['drawer1']?.isOpen" class="">
  </div> -->
</template>

<style scoped>
/* Дополнительные стили */

/* Дополнительные стили */

/* Overlay transition styles */
/* prettier-ignore  */
.fade-enter-acitve, .fade-leave-acitve {
  transition: opacity 0.5s ease-in-out;
}
/* prettier-ignore  */
.fade-enter, .fade-leave-to {
  opacity: 0;
}
/* prettier-ignore  */
.fade-enter-to, .fade-leave {
  opacity: 0.5; /* This matches the initial opacity of the overlay */
}

.drawer-enter-active,
.drawer-leave-active {
  transition: all 0.5s ease;
}

.drawer-enter-from,
.drawer-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
</style>
